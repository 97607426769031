import ResizableRect from "react-resizable-rotatable-draggable";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./style.module.css";
import { updateBoxes } from "../../features/areas/areasSlice";
import { useAreas } from "../../hooks/useAreas";
import { colors } from "../../constants/colors";

export function ResizebleBox({
  box,
  setSelectedBox,
  selectedBox,
  index,
  schemaEdges,
  metersPerPixel,
  type,
  saveBoxData,
}) {
  const [tasks, setTasks] = useState([]);
  const [tempBox, setTempBox] = useState({});
  const [alert, setAlert] = useState({});
  const [status, setStatus] = useState("");
  const [availableShowTask, setAvailableShowTask] = useState("");

  const dispatch = useDispatch();
  const { boxes, initialBoxes } = useAreas();

  let schemaWidth, schemaHeight, schemaLeft, schemaTop;
  if (type === "edit") {
    schemaWidth = schemaEdges.schemaWidth;
    schemaHeight = schemaEdges.schemaHeight;
    schemaLeft = schemaEdges.schemaLeft;
    schemaTop = schemaEdges.schemaTop;
  }

  useEffect(() => {
    if (box.machineId && initialBoxes) {
      const findBox = initialBoxes.find((item) => item.id == box.machineId);
      if (findBox) {
        setTasks(findBox.tasks);
        setAlert(findBox.alertFlag);
        setStatus(findBox.status);
      } else {
        console.error(`Box with id ${box.machineId} not found.`);
      }
    }
  }, [box, initialBoxes]);

  const handleResize = (style, isShiftKey, type, event) => {
    const { top, left, width, height } = style;
    const copyBoxes = [...boxes];

    copyBoxes[index] = {
      ...copyBoxes[index],
      posY: top * metersPerPixel,
      posX: left * metersPerPixel,
      width: width * metersPerPixel,
      height: height * metersPerPixel,
    };
    updateBox(copyBoxes, index);
  };

  const handleRotate = (rotation) => {
    const copyBoxes = [...boxes];

    copyBoxes[index] = {
      ...copyBoxes[index],
      rotation,
    };
    updateBox(copyBoxes, index);
  };

  const handleDrag = (deltaX, deltaY) => {
    const copyBoxes = [...boxes];
    const newLeft = copyBoxes[index].posX / metersPerPixel + deltaX;
    const newTop = copyBoxes[index].posY / metersPerPixel + deltaY;
    const left = Math.max(
      schemaLeft,
      Math.min(newLeft, schemaWidth - copyBoxes[index].width / metersPerPixel)
    );
    const top = Math.max(
      schemaTop,
      Math.min(newTop, schemaHeight - copyBoxes[index].height / metersPerPixel)
    );

    copyBoxes[index] = {
      ...copyBoxes[index],
      posX: left * metersPerPixel,
      posY: top * metersPerPixel,
    };
    updateBox(copyBoxes, index);
  };

  const updateBox = (copyBoxes, index) => {
    setSelectedBox(copyBoxes[index]);
    setTempBox(copyBoxes[index]);
    dispatch(updateBoxes(copyBoxes));
  };

  const handleBoxSelect = (e) => {
    if (type === "edit") {
      e.stopPropagation();
      setSelectedBox(box);
    }
  };

  const handleReturn = () => {
    setTimeout(() => {
      setSelectedBox(tempBox);
    }, 0);
    saveBoxData();
  };

  useEffect(() => {
    if (box) {
      const availHeight = Math.floor((box.height / metersPerPixel - 40) / 21);
      setAvailableShowTask(availHeight);
    }
  }, [tasks]);

  return (
    <div>
      <div
        className={styles.resizeble_box}
        onClick={(e) => handleBoxSelect(e)}
        style={{
          width: `${box.width / metersPerPixel}px`,
          height: `${box.height / metersPerPixel}px`,
          top: `${box.posY / metersPerPixel}px`,
          left: `${box.posX / metersPerPixel}px`,
          transform: `rotate(${box.rotation}deg)`,
          position: "absolute",
          boxShadow:
            selectedBox?.id === box.id && type === "edit"
              ? "0 0 20px rgba(0, 0, 0, 1)"
              : "none",
        }}
      >
        {box.image ? (
          <img
            src={`${process.env.REACT_APP_BASE_URL}/box/image/${box.image}`}
            alt="item"
            className={styles.box_image}
            style={{
              width: `${box.width / metersPerPixel}px`,
              height: `${box.height / metersPerPixel}px`,
            }}
          />
        ) : (
          <img
            src={`/noImage.jpg`}
            alt="not uploaded"
            className={styles.box_image}
            style={{
              width: `${box.width / metersPerPixel}px`,
              height: `${box.height / metersPerPixel}px`,
            }}
          />
        )}
        <div className={styles.header}>
          <p className={styles.title} title={`${box.name}`}>
            {box.name}
          </p>
        </div>
        {tasks && type === "view" && (
          <div className={styles.list}>
            {tasks.slice(0, availableShowTask).map((task) => (
              <p
                className={styles.item}
                key={task.id}
                style={{
                  backgroundColor: `${colors[task.status.color].bg}`,
                  color: `${colors[task.status.color].text}`,
                }}
              >
                {task.text}
              </p>
            ))}
            {tasks.length >= availableShowTask && (
              <div className={styles.hidden_task}>
                {tasks.length - availableShowTask}
              </div>
            )}
          </div>
        )}

        {alert.active && type === "view" && (
          <img className={styles.alert} src="/alert.svg" alt="alert" />
        )}
        {type === "view" && status !== "active" && (
          <div className={styles.status}>{status}</div>
        )}
      </div>

      {selectedBox?.id === box.id && type === "edit" && (
        <ResizableRect
          top={box.posY / metersPerPixel}
          left={box.posX / metersPerPixel}
          width={box.width / metersPerPixel}
          height={box.height / metersPerPixel}
          rotateAngle={+box.rotation}
          onDrag={handleDrag}
          onRotate={handleRotate}
          onResize={handleResize}
          zoomable="se"
          rotatable
          onResizeStart={() => setTempBox(selectedBox)}
          onResizeEnd={handleReturn}
          onRotateStart={() => setTempBox(selectedBox)}
          onRotateEnd={handleReturn}
          onDragStart={() => setTempBox(selectedBox)}
          onDragEnd={handleReturn}
        />
      )}
    </div>
  );
}

export default ResizebleBox;
