// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__m7ycw {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 25px 80px;

  @media (max-width: 1024px) {
    padding: 20px;
  }
}

.style_header__Ds7Qu {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
}

.style_header_title__0BFnk {
  font-size: 28px;
  font-weight: 700;
}

.style_areas__6Tb0Q {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.style_list__TZ1xQ {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}

.style_area__iRkZn {
  display: flex;
  position: relative;
  width: 32%;
  border: 1px solid #b4b4b4;
  margin-bottom: 20px;
}

.style_area_content__M9Z01 {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.style_area_content__name__AEqcv {
  font-size: 24px;
  font-weight: 700;
  color: black;
  margin-bottom: 20px;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Areas/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,kBAAkB;;EAElB;IACE,aAAa;EACf;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":[".container {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  padding: 25px 80px;\n\n  @media (max-width: 1024px) {\n    padding: 20px;\n  }\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  justify-content: space-between;\n  margin-bottom: 40px;\n}\n\n.header_title {\n  font-size: 28px;\n  font-weight: 700;\n}\n\n.areas {\n  font-size: 24px;\n  font-weight: 700;\n  margin-bottom: 20px;\n}\n\n.list {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 20px;\n}\n\n.area {\n  display: flex;\n  position: relative;\n  width: 32%;\n  border: 1px solid #b4b4b4;\n  margin-bottom: 20px;\n}\n\n.area_content {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n}\n\n.area_content__name {\n  font-size: 24px;\n  font-weight: 700;\n  color: black;\n  margin-bottom: 20px;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__m7ycw`,
	"header": `style_header__Ds7Qu`,
	"header_title": `style_header_title__0BFnk`,
	"areas": `style_areas__6Tb0Q`,
	"list": `style_list__TZ1xQ`,
	"area": `style_area__iRkZn`,
	"area_content": `style_area_content__M9Z01`,
	"area_content__name": `style_area_content__name__AEqcv`
};
export default ___CSS_LOADER_EXPORT___;
