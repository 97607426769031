import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useRegisterMutation,
  useUpdateByIdMutation,
  useUpdateMutation as useUpdateMutationBox,
} from "../../app/services/box";
import { useUpdateMutation as useUpdateMutationArea } from "../../app/services/areas";
import { fetchArea, updateBoxes } from "../../features/areas/areasSlice";
import { deleteBox } from "../../features/box/boxSlice";
import { useAreas } from "../../hooks/useAreas";
import useScreenResize from "../../hooks/useScreenResize";
import ResizebleBox from "../../components/ResizableBox";

import { Button, CircularProgress, Divider, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Snackbar from "@mui/material/Snackbar";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ModalChangeImageArea from "../../components/ModalChangeImageArea";

import styles from "./style.module.css";

function Editing() {
  const [metersPerPixel, setMetersPerPixel] = useState(1);
  const [schemaEdges, setSchemaEdges] = useState({});
  const [selectedBox, setSelectedBox] = useState({});
  const [updateAreaName, setUpdateAreaName] = useState(false);
  const [updateAreaWidth, setUpdateAreaWidth] = useState(false);
  const [areaName, setAreaName] = useState("");
  const [areaWidth, setAreaWidth] = useState(0);
  const [areaHeight, setAreaHeight] = useState(0);
  const [areaImageRatio, setAreaImageRatio] = useState(0);
  const [selectedAreaImage, setSelectedAreaImage] = useState(null);
  const [externalUrl, setExternalUrl] = useState("");
  const [updateUrlError, setUpdateUrlError] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [updateBox] = useUpdateMutationBox();
  const [updateById] = useUpdateByIdMutation();
  const [registerBox] = useRegisterMutation();
  const [updateArea] = useUpdateMutationArea();
  const { area, boxes, initialBoxes, isLoading } = useAreas();
  const { windowWidth, windowHeight } = useScreenResize();

  useEffect(() => {
    if (selectedBox.id) {
      const foundBox = boxes.find((box) => box.id == selectedBox.id);
      if (foundBox) {
        setSelectedBox(foundBox);
      }
    }
  }, [selectedBox.id, boxes]);

  useEffect(() => {
    if (id) {
      dispatch(fetchArea(id));
    }
  }, [id]);

  useEffect(() => {
    if (area) {
      setAreaName(area.name);
      setAreaWidth(area.plan_width);
      setAreaHeight(area.plan_height);
      setExternalUrl(area.externalApiUrl);
      setAreaImageRatio(area.plan_width / area.plan_height);
    }
  }, [area]);

  useEffect(() => {
    if (area) {
      setSchemaEdges({
        schemaWidth: windowWidth * 0.75,
        schemaHeight:
          ((windowWidth * 0.75) / area.plan_width) * area.plan_height,
        schemaLeft: 0,
        schemaTop: 0,
      });
      setMetersPerPixel(area.plan_width / windowWidth / 0.75);
    }
  }, [windowWidth, windowHeight, area]);

  const saveBoxData = async () => {
    try {
      const boxData = {
        boxes,
        area: {
          id,
        },
      };
      if (boxes && boxData && id) {
        await updateBox(boxData);
      }
    } catch (error) {
      console.error("handleSaveBoxes error", error);
    }
  };

  const handleInputChange = (event, property) => {
    const value = event.target.value;

    if (value !== "" && selectedBox.id) {
      const updatedBoxes = boxes.map((box) => {
        if (box.id === selectedBox.id) {
          const updatedBox = { ...box, [property]: parseFloat(value) };
          setSelectedBox(updatedBox);
          return updatedBox;
        }
        return box;
      });
      dispatch(updateBoxes(updatedBoxes));
    }
  };

  const handleAddDeleteClick = async (boxId) => {
    const boxIndex = boxes.findIndex((box) => box.id === boxId);
    saveBoxData();
    if (boxIndex !== -1) {
      try {
        await dispatch(deleteBox(boxId));
        setSelectedBox({});
      } catch (error) {}
    } else {
      const selectedBox = initialBoxes.find((box) => box.id === boxId);
      try {
        const formData = {
          type: "type",
          name: `${selectedBox.number} ${selectedBox.name}`,
          posX: selectedBox.posX,
          posY: selectedBox.posY,
          width: selectedBox.width,
          height: selectedBox.height,
          rotation: selectedBox.rotation,
          area: {
            id,
          },
          machineId: selectedBox.id,
        };

        await registerBox(formData);
      } catch (err) {
        console.error("Error registering supplie:", err);
      }
    }
    dispatch(fetchArea(id));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const idBox = selectedBox.id;
    const boxData = new FormData();
    boxData.append("image", file);
    if (file && idBox) {
      await updateById({ idBox, formData: boxData });
    }
    await dispatch(fetchArea(id));
  };

  const handleAreaName = (e) => {
    const name = e.target.value;
    if (name) {
      setAreaName(name);
    }
  };

  const handleExternalUrl = (e) => {
    const url = e.target.value;
    if (url) {
      setExternalUrl(url);
    }
  };

  const handleAreaWidth = (e) => {
    const width = e.target.value;
    if (width) {
      setAreaWidth(width);
      setAreaHeight((width / areaImageRatio).toFixed(2));
    }
  };

  const saveAreaChange = async () => {
    setUpdateAreaName(false);
    setUpdateAreaWidth(false);
    try {
      const areaData = new FormData();
      areaData.append("name", areaName);
      areaData.append("planWidth", areaWidth);
      areaData.append("planHeight", areaHeight);
      if (externalUrl !== null) {
        areaData.append("externalApiUrl", externalUrl);
      }
      if (selectedAreaImage) {
        areaData.append("image", selectedAreaImage);
      }
      const response = await updateArea({ id, areaData });
      if (response.error) {
        setUpdateUrlError(true);
      }
      dispatch(fetchArea(id));
    } catch (error) {
      console.error("Area saving changes error", error);
    }
  };

  const handleCloseUpdateUrlError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setUpdateUrlError(false);
  };

  return (
    <section className={styles.container}>
      <Snackbar
        open={updateUrlError}
        autoHideDuration={5000}
        onClose={handleCloseUpdateUrlError}
        message={t("generic.linkIncorrect")}
      />
      {isLoading && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 5,
            width: "100%",
            height: "100vh",
            backdropFilter: "blur(5px)",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <header className={styles.header}>
        <Link to={`/`} style={{ textDecoration: "none", color: "black" }}>
          <KeyboardDoubleArrowLeftIcon />
        </Link>
        <div className={styles.property}>
          <p className={styles.property_text}>{t("generic.planName")}</p>
          {!updateAreaName ? (
            <div
              className={styles.property_text}
              onClick={() => setUpdateAreaName(true)}
            >
              {areaName}
            </div>
          ) : (
            <TextField
              variant="outlined"
              name="area name"
              size="small"
              style={{
                width: "120px",
              }}
              value={areaName}
              onChange={(e) => handleAreaName(e)}
              onBlur={saveAreaChange}
              autoFocus
            />
          )}
        </div>
        <div className={styles.property}>
          <p className={styles.property_text}>{t("generic.planWidth")}</p>
          {!updateAreaWidth ? (
            <div
              className={styles.property_text}
              onClick={() => setUpdateAreaWidth(true)}
            >
              {area?.plan_width}m
            </div>
          ) : (
            <TextField
              variant="outlined"
              name="area width"
              type="number"
              size="small"
              style={{
                width: "120px",
              }}
              value={areaWidth}
              onChange={(e) => handleAreaWidth(e)}
              onBlur={saveAreaChange}
              autoFocus
            />
          )}
        </div>

        <ModalChangeImageArea
          saveAreaChange={saveAreaChange}
          setSelectedAreaImage={setSelectedAreaImage}
          selectedAreaImage={selectedAreaImage}
          setAreaHeight={setAreaHeight}
          areaWidth={areaWidth}
        />

        {area && (
          <Link to={`/${area?.id}`} style={{ color: "#000" }} target="_blank">
            {t("generic.fullscreenUrl")}
            {`${process.env.REACT_APP_BASE_URL}/${area?.id}`}
          </Link>
        )}
      </header>

      <div className={styles.content}>
        {area && (
          <div
            onClick={() => setSelectedBox({})}
            className={styles.schema}
            style={{
              width: "75vw",
              height: `calc(75vw / ${area.plan_width} * ${area.plan_height})`,
            }}
          >
            <img
              src={`${process.env.REACT_APP_BASE_URL}/area/image/${area.image}`}
              alt=""
              style={{
                width: "75vw",
                height: `calc(75vw / ${area.plan_width} * ${area.plan_height})`,
                pointerEvents: "none",
                userSelect: "none",
              }}
            />
            {boxes.map((box, index) => (
              <ResizebleBox
                key={box.id}
                index={index}
                box={box}
                setSelectedBox={setSelectedBox}
                selectedBox={selectedBox}
                schemaEdges={schemaEdges}
                metersPerPixel={metersPerPixel}
                type={"edit"}
                saveBoxData={saveBoxData}
              />
            ))}
          </div>
        )}
        <div className={styles.option}>
          {!selectedBox.id ? (
            <div>
              <div className={styles.option_header}>
                <TextField
                  // label="URL"
                  variant="outlined"
                  name="URL"
                  disabled={boxes.length !== 0}
                  fullWidth
                  value={externalUrl}
                  onChange={(e) => handleExternalUrl(e)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={boxes.length !== 0}
                  onClick={() => saveAreaChange()}
                  style={{
                    textTransform: "none",
                    width: "171px",
                    height: "56px",
                  }}
                >
                  reload
                </Button>
              </div>
              <Divider />

              <div className={styles.list}>
                {initialBoxes.map((box) => (
                  <div
                    key={box.id}
                    className={`${styles.list_machine} ${
                      boxes.some((b) => b.machineId == box.id)
                        ? styles.inactive
                        : ""
                    }`}
                  >
                    {box.name} - {box.number}
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={boxes.some((b) => b.machineId == box.id)}
                      onClick={() => handleAddDeleteClick(box.id)}
                      style={{ padding: "0 5px" }}
                    >
                      add
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.controls}>
              <div className={styles.controls_control}>
                <label>Width:</label>
                <TextField
                  variant="outlined"
                  type="number"
                  size="small"
                  inputProps={{
                    min: "0.1",
                    max: area?.plan_width,
                    step: "0.1",
                  }}
                  value={selectedBox?.id ? selectedBox?.width.toFixed(1) : 0}
                  onChange={(e) => handleInputChange(e, "width")}
                />
              </div>
              <div className={styles.controls_control}>
                <label>Height:</label>
                <TextField
                  variant="outlined"
                  type="number"
                  size="small"
                  inputProps={{
                    min: "0.1",
                    max: area?.plan_height,
                    step: "0.1",
                  }}
                  value={selectedBox?.id ? selectedBox?.height.toFixed(1) : 0}
                  onChange={(e) => handleInputChange(e, "height")}
                />
              </div>
              <div className={styles.controls_control}>
                <label>Rotate Angle:</label>
                <TextField
                  variant="outlined"
                  type="number"
                  size="small"
                  inputProps={{
                    min: "0",
                    max: "360",
                  }}
                  value={selectedBox?.id ? selectedBox?.rotation : 0}
                  onChange={(e) => handleInputChange(e, "rotation")}
                />
              </div>
              <div className={styles.box_image}>
                {selectedBox?.image ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/box/image/${selectedBox.image}`}
                    alt="box"
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <img
                    src={`/noImage.jpg`}
                    alt="box"
                    style={{ width: "100%", height: "100%" }}
                  />
                )}

                <Button
                  component="label"
                  variant="outlined"
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    borderColor: "rgba(0, 0, 0, 0.3)",
                    padding: "5px",
                    display: "flex",
                    position: "absolute",
                    alignItems: "center",
                    "&:hover": {
                      borderColor: "rgba(0, 0, 0, 1)",
                      background: "rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <input
                    accept="image/*"
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  <EditIcon />
                </Button>
              </div>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => handleAddDeleteClick(selectedBox.id)}
                style={{
                  height: "40px",
                  padding: "8px 12px",
                }}
              >
                {t("generic.delete")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Editing;
