export const LOCALS = {
  EN: 'en',
  DE: 'de',
}

export const getLanguages = (t) => [
  {
    value: "en",
    label: t("generic.english"),
  },
  {
    value: "de",
    label: t("generic.german"),
  },
];