import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useLoginMutation } from "../../app/services/auth";
import { fetchUser } from "../../features/auth/authSlice";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import styles from "./style.module.css";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    const token = Cookies.get("token");
    if (token && !user) {
      dispatch(fetchUser());
    }
    if (user) {
      navigate("/");
    }
  }, [user, navigate, dispatch]);

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setEmailError(false);
    setPasswordError(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email.includes("@")) {
      setEmailError(true);
      return;
    }

    if (password.length < 3) {
      setPasswordError(true);
      return;
    }

    const formData = {
      email: email,
      password: password,
    };

    try {
      const response = await login(formData).unwrap();
      const token = response.token;
      navigate("/");
      Cookies.set("token", token, { expires: 7 });
      setEmail("");
      setPassword("");
    } catch (err) {
      console.log(err);
      setError(err.data?.message);
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.container_login}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" fontWeight="bold" mb={5}>
            {t("generic.loginToSystem")}
          </Typography>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
              <TextField
                id="outlined-basic"
                label={t("generic.emailAddress")}
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={handleInputChange(setEmail)}
                error={emailError}
                inputProps={{
                  "aria-describedby": emailError
                    ? "email-helper-text"
                    : undefined,
                }}
              />
              {emailError && (
                <Typography id="email-helper-text" color="error">
                  {t("generic.invalidEmail")}
                </Typography>
              )}
            </FormControl>
            <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {t("generic.password")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("generic.password")}
                value={password}
                onChange={handleInputChange(setPassword)}
                error={passwordError}
                inputProps={{
                  "aria-describedby": passwordError
                    ? "password-helper-text"
                    : undefined,
                }}
              />
              {passwordError && (
                <Typography id="password-helper-text" color="error">
                  {t("generic.shortPassword")}
                </Typography>
              )}
            </FormControl>
            {error && <Typography color="error">{error}</Typography>}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              style={{ marginTop: "10px", width: "100%", height: "42px" }}
            >
              {t("generic.submit")}
            </Button>
          </form>
        </Box>
      </div>
    </section>
  );
}

export default Login;
