import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const fetchBox = createAsyncThunk(
  "box/fetchBox",
  async () => {
    try {
      const token = Cookies.get("token");
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/box",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const deleteBox = createAsyncThunk(
  "box/deleteBox",
  async (id, data) => {
    try {
      const token = Cookies.get("token");
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + `/box/${id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const addImageBox = createAsyncThunk(
  "box/addImageBox",
  async (id, formData) => {
    try {
      const token = Cookies.get("token");
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + `/box/${id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const boxSlice = createSlice({
  name: "box",
  initialState: {
    box: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeBox: (state) => {
      state.box = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBox.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBox.fulfilled, (state, action) => {
      state.isLoading = false;
      state.box = action.payload;
      state.error = null;
    });
    builder.addCase(fetchBox.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { removeBox } = boxSlice.actions;

export default boxSlice.reducer;

export const selectBox = (state) => state.box.box;
