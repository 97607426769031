import { configureStore } from "@reduxjs/toolkit";
import { api as authApi } from "./services/auth";
import { areasApi } from "./services/areas";
import { boxApi } from "./services/box";
import authReducer from "../features/auth/authSlice";
import areasReducer from "../features/areas/areasSlice";
import boxReducer from "../features/box/boxSlice";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [areasApi.reducerPath]: areasApi.reducer,
    [boxApi.reducerPath]: boxApi.reducer,

    auth: authReducer,
    areas: areasReducer,
    box: boxReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware, areasApi.middleware, boxApi.middleware),
});

export const AppDispatch = typeof store.dispatch;
