import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import NotFoundPage from "../pages/NotFoundPage";
import FullScreen from "../pages/FullScreen";
import Areas from "../pages/Areas";
import Editing from "../pages/Editing";
import Login from "../pages/Login";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/:id" element={<FullScreen />} />
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<Areas />} />
        <Route path="/areas/:id" element={<Editing />} />
      </Route>
    </Routes>
  );
};
