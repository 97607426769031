import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const boxApi = createApi({
  reducerPath: "boxApi",
  baseQuery,
  endpoints: (builder) => ({
    getBox: builder.query({
      query: () => "box",
    }),
    register: builder.mutation({
      query: (boxData) => {
        return {
          url: "box",
          method: "POST",
          body: boxData,
        };
      },
    }),
    update: builder.mutation({
      query: (boxData) => {
        return {
          url: `box`,
          method: "PUT",
          body: boxData,
        };
      },
    }),
    updateById: builder.mutation({
      query: ({idBox, formData}) => {
        return {
          url: `box/${idBox}`,
          method: "PUT",
          body: formData,
        };
      },
    }),
  }),
});

export const { useGetBoxQuery, useRegisterMutation, useUpdateMutation, useUpdateByIdMutation } =
  boxApi;
