import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRegisterMutation } from "../../app/services/areas";
import { useTranslation } from "react-i18next";
import ModalComponent from "../ModalComponent";

import { Button, TextField, Typography } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

import styles from "../ModalComponent/modalStyles.module.css";

const initialForm = {
  name: "",
  width: 0,
  height: 0,
};

export default function ModalAddArea({ fetchData }) {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [errorResponse, setErrorResponse] = useState("");
  const [registerArea, { isLoading, error }] = useRegisterMutation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageAspectRatio, setImageAspectRatio] = useState(1);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm(initialForm);
    setSelectedFile(null);
  };

  useEffect(() => {
    if (error) {
      console.log(error);
      setErrorResponse(error.data?.message);
    }
  }, [error]);

  useEffect(() => {
    if (!isLoading && !error) {
      handleClose();
      setErrorResponse("");
    }
  }, [isLoading, error, dispatch]);

  const handleChange = (e) => {
    setErrorResponse("");

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    if (selectedFile && e.target.name === "width") {
      setImageAspectRatio(selectedFile.height / e.target.value);
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", form.name);
      formData.append("planWidth", form.width);
      formData.append("planHeight", (form.width * imageAspectRatio).toFixed(2));
      formData.append("image", selectedFile);
      await registerArea(formData);
      fetchData();
    } catch (err) {
      console.error("Error registering group:", err);
    }
  };

  console.log('imageAspectRatio', imageAspectRatio)

  const handleFileChange = (e) => {
    setErrorResponse("");
    const file = e.target.files[0];

    setSelectedFile(file);

    const img = new Image();
    img.onload = () => {
      setImageAspectRatio(img.height / img.width);
    };
    img.src = URL.createObjectURL(file);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{
          padding: "8px 22px",
          textTransform: "none",
          position: "fixed",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: '1000'
        }}
      >
        {t("generic.create")}
      </Button>

      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Typography variant="h5" fontWeight="bold">
              {t("generic.createNew")}
            </Typography>
            <TextField
              value={form.name}
              label={t("generic.name")}
              variant="outlined"
              name="name"
              onChange={handleChange}
              fullWidth
            />
            <TextField
              value={form.width}
              type="number"
              label={t("generic.width")}
              variant="outlined"
              name="width"
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
            />
            <Button
              component="label"
              variant="outlined"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                borderColor: "rgba(0, 0, 0, 0.3)",
                fontSize: "16px",
                height: "56px",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                "&:hover": {
                  borderColor: "rgba(0, 0, 0, 1)",
                  background: "none",
                },
              }}
            >
              <input
                accept="image/*"
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {selectedFile ? selectedFile.name : t("generic.uploadImage")}
            </Button>

            {selectedFile && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected file"
                style={{ maxWidth: "452px", marginTop: "10px" }}
              />
            )}

            {errorResponse &&
              (Array.isArray(errorResponse) ? (
                errorResponse.map((item, index) => (
                  <Typography key={index} color="error">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography color="error">{errorResponse}</Typography>
              ))}

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: "5px 22px" }}
              >
                {t("generic.close")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: "5px 22px" }}
              >
                {t("generic.create")}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
