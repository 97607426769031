import { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalComponent from "../ModalComponent";

import { Button } from "@mui/material";

import styles from "../ModalComponent/modalStyles.module.css";

export default function ModalChangeImageArea({
  saveAreaChange,
  setSelectedAreaImage,
  selectedAreaImage,
  setAreaHeight,
  areaWidth,
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedAreaImage(null);
  };

  const handleSubmit = async () => {
    saveAreaChange();
    handleClose();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedAreaImage(file);

    const img = new Image();
    img.onload = () => {
      const ratio = img.width / img.height;
      const newAreaHeight = (areaWidth / ratio).toFixed(2)
      setAreaHeight(newAreaHeight);
    };
    img.src = URL.createObjectURL(file);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        component="label"
        variant="outlined"
        sx={{
          color: "rgba(0, 0, 0, 0.6)",
          borderColor: "rgba(0, 0, 0, 0.3)",
          padding: "5px",
          display: "flex",
          alignItems: "center",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 1)",
            background: "rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        {t("generic.changeImage")}
      </Button>

      <ModalComponent open={open} handleClose={handleClose}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <Button
              component="label"
              variant="outlined"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                borderColor: "rgba(0, 0, 0, 0.3)",
                fontSize: "16px",
                height: "56px",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                "&:hover": {
                  borderColor: "rgba(0, 0, 0, 1)",
                  background: "none",
                },
              }}
            >
              <input
                accept="image/*"
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {selectedAreaImage
                ? selectedAreaImage.name
                : t("generic.uploadImage")}
            </Button>

            {selectedAreaImage && (
              <img
                src={URL.createObjectURL(selectedAreaImage)}
                alt="Selected file"
                style={{ maxWidth: "452px", marginTop: "10px" }}
              />
            )}

            <div className={styles.button_group}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ padding: "5px 22px" }}
              >
                {t("generic.close")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ padding: "5px 22px" }}
              >
                {t("generic.submit")}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
