import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";

export const areasApi = createApi({
  reducerPath: "areasApi",
  baseQuery,
  endpoints: (builder) => ({
    getAreas: builder.query({
      query: () => "area",
    }),
    register: builder.mutation({
      query: (areaData) => {
        return {
          url: "area",
          method: "POST",
          body: areaData,
        };
      },
    }),
    update: builder.mutation({
      query: ( {id, areaData} ) => {
        return {
          url: `area/${id}`,
          method: "PUT",
          body: areaData,
        };
      },
    }),
  }),
});

export const { useGetAreaQuery, useRegisterMutation, useUpdateMutation } =
  areasApi;
