import styles from "./style.module.css";

function NotFoundPage() {

  return (
    <section className={styles.container}>
      <div>not found page</div>
    </section>
  );
}

export default NotFoundPage;
