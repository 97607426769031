import { AppRoutes } from "./routes/AppRoutes";
import LocaleProvider from "./i18n/LocaleProvider.js";
// import ThemeMuiProvider from "./providers/ThemeMuiProvider.js";
import "./App.css";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function App() {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   fetchData(dispatch);
  // }, [dispatch]);

  return (
    // <ThemeMuiProvider>
    <LocaleProvider>
      <AppRoutes />
    </LocaleProvider>
    // </ThemeMuiProvider>
  );
}

export default App;
