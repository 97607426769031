import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../app/services/auth";
import Cookies from "js-cookie";

export const fetchUser = createAsyncThunk(
  "auth/fetchUser",
  async (_, thunkAPI) => {
    try {
      const token = Cookies.get("token");
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/auth/me', {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, token: null, isLoading: false, error: null },
  reducers: {
    removeUser: (state) => {
      state.user = null;
      state.token = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.statusCode === 401) {
        state.error = true;
      } else {
        state.error = false;
        state.user = action.payload;
      }
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token;
        state.user = payload.user;
        state.error = false;
      }
    );
  },
});

export const { removeUser } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
