import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetchArea } from "../../features/areas/areasSlice";
import { useAreas } from "../../hooks/useAreas";
import useScreenResize from "../../hooks/useScreenResize";
import ResizebleBox from "../../components/ResizableBox";
import styles from "./style.module.css";

function FullScreen() {
  const [metersPerPixel, setMetersPerPixel] = useState(1);
  const [viewStyle, setViewStyle] = useState({});

  const { id } = useParams();
  const dispatch = useDispatch();

  const { area, boxes, error } = useAreas();
  const { windowWidth, windowHeight } = useScreenResize();
  const navigate = useNavigate();

  if (error) {
    navigate("/");
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchArea(id));
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchArea(id));
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (area?.plan_width / area?.plan_height > windowWidth / windowHeight) {
      setMetersPerPixel(area?.plan_width / windowWidth);
      setViewStyle({
        width: "100vw",
        height: `calc(100vw / ${area?.plan_width} * ${area?.plan_height})`,
      });
    } else {
      setMetersPerPixel(area?.plan_height / windowHeight);
      setViewStyle({
        width: `calc(100vh / ${area?.plan_height} * ${area?.plan_width})`,
        height: `100vh`,
      });
    }
  }, [windowWidth, windowHeight, area]);

  return (
    <section className={styles.container}>
      {area && (
        <div className={styles.schema} style={viewStyle}>
          <img
            src={`${process.env.REACT_APP_BASE_URL}/area/image/${area.image}`}
            className={styles.area}
            alt="area"
            style={viewStyle}
          />
          {boxes.map((box, index) => (
            <ResizebleBox
              key={box.id}
              index={index}
              box={box}
              metersPerPixel={metersPerPixel}
              type={"view"}
            />
          ))}
        </div>
      )}
    </section>
  );
}

export default FullScreen;
