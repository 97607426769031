import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useAreas = () => {
  const boxes = useSelector(state => state.areas.boxes);
  const area = useSelector(state => state.areas.area);
  const initialBoxes = useSelector(state => state.areas.initialBoxes);

  const error = useSelector(state => state.areas.error);
  const isLoading = useSelector(state => state.areas.isLoading);

  return useMemo(() => ({ boxes, initialBoxes,area, error, isLoading }), [boxes, initialBoxes,area, error, isLoading]);
}

