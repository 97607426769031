import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {
  START_BOX_LEFT_POS,
  START_BOX_TOP_POS,
  START_SCALE_HIGHT_BOX,
  START_SCALE_WIDTH_BOX,
} from "../../constants/variables";

export const fetchAreas = createAsyncThunk("areas/fetchAreas", async () => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/area", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchArea = createAsyncThunk("areas/fetchArea", async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + `/area/${id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    const updatedBoxes = data.boxes.map((box) => ({
      ...box,
      id: parseFloat(box.id),
      height: parseFloat(box.height),
      width: parseFloat(box.width),
      posX: parseFloat(box.posX),
      posY: parseFloat(box.posY),
      rotation: parseFloat(box.rotation),
    }));

    const { planWidth, planHeight } = data;
    const numericPlanWidth = parseFloat(planWidth, 10);
    const numericPlanHeight = parseFloat(planHeight, 10);

    const preparedArea = {
      ...data,
      plan_height: numericPlanHeight,
      plan_width: numericPlanWidth,
    };

    const initialBoxes = data.response
      ? JSON.parse(data.response).map((box) => ({
          ...box,
          width: numericPlanWidth * START_SCALE_WIDTH_BOX,
          height:
            numericPlanWidth * START_SCALE_WIDTH_BOX * START_SCALE_HIGHT_BOX,
          posY: START_BOX_LEFT_POS,
          posX: START_BOX_TOP_POS,
          rotation: 0,
        }))
      : [];

    return {
      ...data,
      boxes: updatedBoxes,
      initialBoxes,
      preparedArea,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const deleteArea = createAsyncThunk("areas/deleteArea", async (id) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + `/area/${id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const areasSlice = createSlice({
  name: "areas",
  initialState: {
    area: {},
    boxes: [],
    initialBoxes: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    removeArea: (state) => {
      state.area = {};
      state.boxes = [];
      state.initialBoxes = [];
      state.isLoading = false;
      state.error = null;
    },
    updateBoxes: (state, action) => {
      state.boxes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArea.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchArea.fulfilled, (state, action) => {
      state.isLoading = false;
      state.area = action.payload.preparedArea;
      state.boxes = action.payload.boxes;
      state.initialBoxes = action.payload.initialBoxes;
      state.error = null;
    });
    builder.addCase(fetchArea.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "An unexpected error occurred";
    });
  },
});

export const { removeArea, updateBoxes } = areasSlice.actions;

export default areasSlice.reducer;

export const selectAreas = (state) => state.areas.areas;
