import { createContext, useContext, useEffect, useState } from "react";
import i18n from "./index";

export const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext);
}

function LocaleProvider({ children }) {
  const [language, setLanguage] = useState(process.env.REACT_APP_LANG);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export default LocaleProvider;
