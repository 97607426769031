import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import {
  deleteArea,
  fetchAreas,
  removeArea,
} from "../../features/areas/areasSlice";
import { removeUser } from "../../features/auth/authSlice";
import ModalAddArea from "../../components/ModalAddArea";

import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "./style.module.css";

function Areas() {
  const [areas, setAreas] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await dispatch(fetchAreas());
      setAreas(response.payload);
    } catch (error) {
      console.error("Error fetching machine data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLogout = () => {
    Cookies.remove("token");
    dispatch(removeUser());
    dispatch(removeArea());
    navigate("/login");
  };

  const handleDelete = async (id) => {
    await dispatch(deleteArea(id));
    fetchData();
  };

  return (
    <section className={styles.container}>
      <ModalAddArea fetchData={fetchData} />
      <div className={styles.header}>
        <p className={styles.header_title}>InfraVisualizer</p>

        <Button
          variant="outlined"
          color="primary"
          onClick={handleLogout}
          style={{ padding: "8px 22px", textTransform: "none" }}
        >
          {t("generic.logout")}
        </Button>
      </div>
      <p className={styles.areas}>list of areas</p>

      <div className={styles.list}>
        {areas ? (
          areas.map((area) => (
            <div className={styles.area}>
              <DeleteIcon
                onClick={() => handleDelete(area.id)}
                style={{
                  position: "absolute",
                  cursor: 'pointer',
                  top: "2px",
                  right: "2px",
                }}
              />
              <img
                src={`${process.env.REACT_APP_BASE_URL}/area/image/${area.image}`}
                alt=""
                style={{
                  width: "100px",
                  height: "100px",
                }}
              />
              <div className={styles.area_content}>
                <Link
                  to={`/areas/${area.id}`}
                  className={styles.area_content__name}
                >
                  {area.name}
                </Link>
                <Link
                  to={`/${area.id}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  URL (full screen view)
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </section>
  );
}

export default Areas;
