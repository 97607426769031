// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__w6lJ5 {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.style_schema__vp2em {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  position: relative;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.style_area__VykeR {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/FullScreen/style.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,oBAAoB;EACpB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,yBAAiB;UAAjB,iBAAiB;AACnB","sourcesContent":[".container {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n}\n\n.schema {\n  display: flex;\n  box-sizing: border-box;\n  justify-content: center;\n  position: relative;\n  pointer-events: none;\n  user-select: none;\n}\n\n.area {\n  pointer-events: none;\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__w6lJ5`,
	"schema": `style_schema__vp2em`,
	"area": `style_area__VykeR`
};
export default ___CSS_LOADER_EXPORT___;
