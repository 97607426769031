import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { fetchUser } from "../features/auth/authSlice";
import { useAuth } from "../hooks/useAuth";

export const ProtectedRoute = () => {
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, error, isLoading } = useAuth();

  useEffect(() => {
    const token = Cookies.get("token");

    if (token && !user) {
      dispatch(fetchUser());
    } else if (!token) {
      navigate("/login");
    }
    setIsTokenLoaded(true);
  }, [dispatch, navigate, user, error]);

  useEffect(() => {
    if (error) {
      Cookies.remove("token");
      navigate("/login");
    }
  }, [error, isLoading, navigate]);

  if (!isTokenLoaded) {
    return null;
  }

  if (!error && !isLoading) {
    return (
      <div
        style={{ display: "flex", height: "100vh", flexDirection: "column" }}
      >
        <Outlet />
      </div>
    );
  }
};

